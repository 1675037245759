import * as React from "react";
import { useLocation } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "gatsby";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";

import continentCities from "./cities.json";

import authors from "../../authors.json";

import "./AboutUsPage.css";

const HomePage = ({ pageContext }) => {
  const [selectedContinent, setSelectedContinent] = React.useState(
    continentCities[0]
  );

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div className="about-us-page">
        <Section backgroundColor={"#fff"}>
          <div className="who-we-are-section">
            <div className="who-we-are-text-section">
              <div className="who-we-are-content">
                <iframe
                  width="50%"
                  height="300"
                  src="https://www.youtube.com/embed/mTvkjVF1LUs"
                ></iframe>
                <div className="about-us-text">
                  <SectionHeading>
                    WHO WE ARE & WHAT MAKES US SPECIAL?
                  </SectionHeading>
                  <div>
                    <p>
                      We are Travel-Lingual. We are a travel marketplace and
                      price comparison platform. We provide travelers with
                      access to over half a million tours, activities, day
                      trips, and transfers all over the world. We help travelers
                      from across the globe by allowing them to research and
                      book unforgettable travel experiences at the best possible
                      prices.
                    </p>

                    <p>
                      We work closely with travel brands and tour providers to
                      connect our audience with exceptional travel experiences,
                      curated from the finest offerings and exclusive deals
                      available.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        {authors
          .filter((author) => author.linkedin)
          .map((author, index) => (
            <Section backgroundColor={index % 2 == 0 ? "#ef7b0e52" : "#fff"}>
              {index === 0 && (
                <SectionHeading component="h2">MEET THE TEAM</SectionHeading>
              )}
              <div className="meet-the-team-member">
                <Link
                  to={`/author/${author.name.toLowerCase()}`}
                  style={{ width: "30%", height: 300 }}
                >
                  <div
                    className="team-member-image"
                    style={{ backgroundImage: `url(${author.image})` }}
                  />
                </Link>
                <div className="team-member-content">
                  <Link to={`/author/${author.name.toLowerCase()}`}>
                    <div className="team-member-position">
                      <span className="team-member-name">
                        {(author.fullName
                          ? author.fullName
                          : author.name
                        ).toUpperCase()}
                      </span>
                      <span className="team-member-role">
                        {author.role.toUpperCase()}
                      </span>
                    </div>
                  </Link>
                  <div className="team-member-description">
                    {author.description.map((paragraph) => (
                      <p>{paragraph}</p>
                    ))}
                  </div>
                  <div>
                    {author.email && (
                      <a href={`mailto:${author.email}`} target="_blank">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{
                            marginRight: 10,
                            color: "#000",
                            fontSize: 20,
                          }}
                        />
                      </a>
                    )}
                    {author.youtube && (
                      <a href={author.youtube} target="_blank">
                        <FontAwesomeIcon
                          icon={faYoutube}
                          style={{
                            marginRight: 10,
                            color: "#000",
                            fontSize: 20,
                          }}
                        />
                      </a>
                    )}
                    {author.linkedin && (
                      <a href={author.linkedin} target="_blank">
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{
                            marginRight: 10,
                            color: "#000",
                            fontSize: 20,
                          }}
                        />
                      </a>
                    )}
                    {author.tiktok && (
                      <a href={author.tiktok} target="_blank">
                        <FontAwesomeIcon
                          icon={faTiktok}
                          style={{
                            marginRight: 10,
                            color: "#000",
                            fontSize: 20,
                          }}
                        />
                      </a>
                    )}
                    {author.facebook && (
                      <a href={author.facebook} target="_blank">
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          style={{
                            marginRight: 10,
                            color: "#000",
                            fontSize: 20,
                          }}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </Section>
          ))}
      </div>
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default HomePage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"About Us | Travel-Lingual"}
      description={
        "Learn more about Travel-Lingual - Your trusted gateway to global adventures. Discover our mission, values, and the passionate team behind your travel aspirations. Join us in making the world your playground."
      }
      pathname={location.pathname}
    />
  );
};
